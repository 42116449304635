import React from "react"
import Reusable from "../../components/articleLayout"
import BraveIcon from "../../images/bat.png"
import Layout from "../../components/layout"
import BraveCard from "../../images/TwitterCards/BraveCard.png"
import SEO from "../../components/seo"

const BraveBrowser = () => (
  <Layout>
    <SEO
      title="Earn Unlimited BAT with Brave"
      description="Learn how to earn unlimited BAT with Brave Browser."
      article={true}
      image={BraveCard}
      pathname="/crypto/brave-browser"
    />
    <Reusable
      Logo={BraveIcon}
      ImageAlt="Brave Logo"
      From="www.brave.com"
      Title="Earn BAT with Brave"
      Name="Basic Attention Token"
      Ticker="BAT"
      Value="Unlimited"
      Address="https://brave.com/how080"
      Step1="Click the “Claim Now” button on this page."
      Step2="You will be redirected to brave.com."
      Step3="Download Brave and install."
      Step4="Launch Brave Browser and enbale Ad Rewards."
      Step5="Congratulations! You are now being paid to browse."
      Introduction="Brave Browser is by far my favorite way to earn some extra crypto. If you are reading this article you are more than likely using Firefox, Chrome or Safari. All good browsers, don’t get me wrong but… are you getting paid for using them? Also how are those ad blockers working for you? Brave has you covered, out of the box Brave blocks all ads and trackers as you browse the Interwebs. Additionally, you have the option to enable ads only approved by Brave and you will then be rewarded in BAT for every ad served to you in the form of a notification.  At the end of each month your rewards will be transferred to your Verified Uphold Wallet."
      Introduction2="But wait, there’s more. You also have to ability to tip Websites, Youtube Channels, Twitch Channels, Twitter Accounts, Vimeo Channels, Reddit Accounts and GitHub Accounts. For instance, say you are on a cool website, reading a really cool article about Brave Browser and think “Gee, I wish there was a way to tip the creator of website for doing a good job”. All you have to do is click the BAT icon at the top of your browser, if the website is a Brave Verified Creator you can send them a tip of your choice. Enjoy!"
    />
  </Layout>
)

export default BraveBrowser
